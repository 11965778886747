<!-- =========================================================================================
    File Name: Register.vue
    Description: Register Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div class="h-screen">
    <vx-card>
      <div slot="no-body" class="full-page-bg-color">
        <!-- <iframe
          width="100%"
          allow="camera *;microphone *"
          height="600"
          :src="meeting_link"
          v-if="meeting_link"
          allowfullscreen
        ></iframe> -->
        <div class="p-10">
          <img
            :src="logoImg"
            :alt="`${$t('basic.Logo')}`"
            class="block mx-auto mb-5"
            style="max-width: 280px"
          />
          <h3 class="text-center text-color-base my-8 block">
            {{ siteTitle }}
          </h3>
          <p class="block py-1 text-danger" v-if="err">
            {{ err }}
          </p>
          <vs-button
            class="block mx-auto"
            v-else-if="meeting_link"
            @click="goToMeeting()"
          >
            {{ $t("onlineClass.run_the_meeting") }}</vs-button
          >
        </div>
      </div>
    </vx-card>
  </div>
</template>
<script>

import { RepositoryFactory } from '@/Repositories/RepositoryFactory'
const TeacherRepository = RepositoryFactory.get(
  'TeachersModule',
  'OnlineClass'
)
export default {


  data () {
    return {
      meeting_link: '',
      err:''
    }
  },
  created () {
    this.createMeeting()
  },
  methods: {

    createMeeting () {
      TeacherRepository.getStartMeetingLink(this.$route.params.meeting_id)
        .then((res) => {
          this.meeting_link = res
          this.goToMeeting()
        })
        .catch((err) => {
          this.err = err.error.message
          this.errMsg(err)
        })
    },
    goToMeeting (link = this.meeting_link) {
      window.open(link, '_blank')
    }
  },
  computed:{

  }
}
</script>
